import { COUNTIES_KEYS, isInsideCounty } from "./CountyChecker";

export const FareCalculator = (
  distance,
  time,
  rateDetails,
  instructionData,
  decimal,
  auth,
  bookingData
) => {
  // console.log("auth", auth);

  
  // console.log("=====================> Bookings Data   ", bookingData);
  
// "pickup": {"add": "V2X5+R7, Juja, Kenya", "lat": -1.1004017, "lng": 37.0079835, "source": "init"}

  // console.log("=====================>pickup data ", bookingData.pickup);

  // console.log("=====================>county data ", userCounty);

  


  let distanceRate = 0;
  if (auth && auth.profile && auth.profile.corporateadmin) {
    distanceRate = rateDetails.corporate_rate_per_unit_distance;
    // console.log(
    //   `===================> distanceRate ${distanceRate} corporateadmin ${auth.profile.corporateadmin}`
    // );
  } else {

    distanceRate = rateDetails.rate_per_unit_distance;
    // console.log("===================> distanceRate", distanceRate);

    const userCounty = isInsideCounty(bookingData.pickup.lat, bookingData.pickup.lng);


    // todo:// change the rate according to the county

    if (userCounty) {
      if (userCounty == COUNTIES_KEYS.KIAMBU) {

        distanceRate = rateDetails.kiambu_rate_per_unit_distance;

        console.log(`======>USER IS  IN ${userCounty} distanceRate ${distanceRate}`);
      }
      else if (userCounty == COUNTIES_KEYS.MOMBASA) {
        distanceRate = rateDetails.mombasa_rate_per_unit_distance;

        console.log(`======>USER IS  IN ${userCounty} distanceRate ${distanceRate}`);
      }
      else {
        distanceRate = rateDetails.rate_per_unit_distance;

        console.log(`======>USER IS  IN ${userCounty} distanceRate ${distanceRate}`);
      }
    }
    else{
      distanceRate = rateDetails.rate_per_unit_distance;
      console.log(`======>USER IS  IN ${userCounty} distanceRate ${distanceRate}`);
    }


  }

  let baseCalculated =
    parseFloat(distanceRate) * parseFloat(distance) +
    parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600);
  if (rateDetails.base_fare > 0) {
    baseCalculated = baseCalculated + rateDetails.base_fare;
  }
  if (instructionData && instructionData.parcelTypeSelected) {
    baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
  }
  if (instructionData && instructionData.optionSelected) {
    baseCalculated = baseCalculated + instructionData.optionSelected.amount;
  }
  let total =
    baseCalculated > parseFloat(rateDetails.min_fare)
      ? baseCalculated
      : parseFloat(rateDetails.min_fare);
  let convenienceFee = 0;
  if (
    rateDetails.convenience_fee_type &&
    rateDetails.convenience_fee_type == "flat"
  ) {
    convenienceFee = rateDetails.convenience_fees;
  } else {
    convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
  }
  let grand = total + convenienceFee;

  // Function to round up to the next multiple of 5
  function roundUpToNearestMultipleOf5(value) {
    return Math.ceil(value / 5) * 5;
  }

  // ...

  // Round totalCost and grandTotal to the nearest multiple of 5
  const roundedTotalCost = roundUpToNearestMultipleOf5(total);
  const roundedGrandTotal = roundUpToNearestMultipleOf5(grand);

  // ...

  // return {
  //   totalCost: 0,
  //   grandTotal: 0,
  //   convenience_fees: 0,
  // };

  return {
    totalCost: parseFloat(roundedTotalCost.toFixed(decimal)),
    grandTotal: parseFloat(roundedGrandTotal.toFixed(decimal)),
    convenience_fees: parseFloat(convenienceFee.toFixed(decimal)),
  };
};
